import { CommonModule } from '@angular/common';
import { ChangeDetectorRef, Component, OnDestroy, OnInit, inject } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { DEFAULT_LANGUAGE } from '@lib/constants';
import { AppSession } from '@lib/interfaces';
import { EventMediator } from '@lib/mediator';
import { AuthService, SidebarService } from '@lib/services';
import { ChatService } from '@lib/services/chat';
import { AppLanguage, LanguageService } from '@lib/services/language';
import { AppTheme, ThemeService } from '@lib/services/theme';
import { LanguageSwitcherComponent } from '@pages/nesya/components/language-switcher/language-switcher.component';
import { ThemeSwitcherComponent } from '@pages/nesya/components/theme-switcher/theme-switcher.component';
import { Subject, takeUntil } from 'rxjs';

@Component({
    selector: 'app-sidebar',
    standalone: true,
    templateUrl: './sidebar.component.html',
    imports: [CommonModule, RouterModule, ThemeSwitcherComponent, LanguageSwitcherComponent],
})
export class SidebarComponent implements OnInit, OnDestroy {
    emailstring = 'mailto:hai@tenangaja.co.id?Subject=Feedback%20for%20Nesya&body=';

    currentTheme!: AppTheme | null;
    currentLanguage: AppLanguage = DEFAULT_LANGUAGE;
    private readonly _router = inject(Router);
    private readonly _authService = inject(AuthService);
    private readonly _sidebarService = inject(SidebarService);
    private readonly _themeService = inject(ThemeService);
    private readonly _languageService = inject(LanguageService);
    private readonly _chatService = inject(ChatService);
    private readonly _eventMediator = inject(EventMediator);
    private readonly _cdr = inject(ChangeDetectorRef);

    isVoiceRoomActive = false;
    currentSession!: AppSession | null;

    private readonly _destroy$ = new Subject();

    closeSidebar(): void {
        this._sidebarService.close();
    }

    ngOnInit(): void {
        this._languageService.currentLanguage$
            .pipe(takeUntil(this._destroy$))
            .subscribe((language) => (this.currentLanguage = language));
        const browserDetection = navigator.userAgent;
        if (browserDetection.includes('iPhone')) {
            document.getElementById('sideBar')?.classList.add('pb-20');
        } else {
            document.getElementById('sideBar')?.classList.remove('pb-20');
        }
        this._themeService.currentTheme$
            .pipe(takeUntil(this._destroy$))
            .subscribe((theme) => (this.currentTheme = theme));
        this._authService.currentSession$
            .pipe(takeUntil(this._destroy$))
            .subscribe((session) => (this.currentSession = session));
        this._eventMediator.pathChange.subscribe((response) => {
            if (response === '/speech') {
                this.isVoiceRoomActive = true;
            } else {
                this.isVoiceRoomActive = false;
            }
            this._cdr.detectChanges();
        });
    }

    getVoiceRoom(): void {
        this._chatService.isVoiceRoomSidebar.next(true);
        this._chatService.isVoiceRoomSidebar$.subscribe((response) => (this.isVoiceRoomActive = response));
        this._chatService
            .getRooms(this.currentSession?.userId || '', true)
            .pipe(takeUntil(this._destroy$))
            .subscribe((response) => this._chatService.chatRooms$.next(response));
        const urlPattern = /^\/speech\/[a-zA-Z0-9]+$/;
        if (!urlPattern.test(this._router.url)) {
            this._router.navigate(['/speech']);
        }
    }

    getRooms(): void {
        this._chatService.isVoiceRoomSidebar.next(false);
        this._chatService.isVoiceRoomSidebar$.subscribe((response) => (this.isVoiceRoomActive = response));
        this._chatService
            .getRooms(this.currentSession?.userId || '', false)
            .pipe(takeUntil(this._destroy$))
            .subscribe((response) => this._chatService.chatRooms$.next(response));
        this._router.navigate(['']);
    }

    handleThemeChange(theme: AppTheme): void {
        this._themeService.setTheme(theme);
    }

    handleLanguageChange(language: AppLanguage): void {
        this._languageService.setLanguage(language);
    }

    onClickSignOut(): void {
        this.closeSidebar();
        this._authService.logout();
        this._router.navigate(['/auth/login']);
    }

    ngOnDestroy(): void {
        this._destroy$.complete();
        this._destroy$.unsubscribe();
    }
}
