import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { SidebarComponent } from '@lib/components';
import { SidebarService } from '@lib/services';

@Component({
    selector: 'app-layout-with-sidebar',
    standalone: true,
    imports: [CommonModule, SidebarComponent],
    templateUrl: './layout-with-sidebar.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LayoutWithSidebarComponent {
    isSidebarOpen$ = inject(SidebarService).isSidebarOpen$;
}
